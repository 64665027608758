import { template as template_e490c69daf2847e2b76f87e4da8f94d4 } from "@ember/template-compiler";
const WelcomeHeader = template_e490c69daf2847e2b76f87e4da8f94d4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
