import { template as template_12c25cb152e545e89c9e961a75acf1c7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_12c25cb152e545e89c9e961a75acf1c7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
